import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";

export default createStore({
  state: {
    practitioner: [],
    patient: [],
    sexe: null,
    token: null,
    idPatient: null,
    parametres: [],
    amount: null,
    displayPrice: null,
    picture_practitioner: "",
    visio: null,
    visioActiveEvent: null,
    maxPatient: null,
    black_listed: null,
    payementType: "",
    motif: "",
    confirmation: null,
    idEvent: false,
    slug: "",
    profession: "",
    address: "",
    dataEventType: [],
    dataEvent: [],
    event_types: [],
    text: "",
    client_secret: "",
    charge_serialized: [],
    filiation: null,
    filiation_idPatient: null,
    namePractitioner: "",
    namePatient: "",
    nameFiliation: "",
    addressPractitioner: "",
    patientRegistration: false,
    dateRDV: "",
    startdate: "",
    enddate: "",
    id_place: null,
    activeEvent: null,
    stripe: [],
    rights: "",
    title_markup: "",
  },
  mutations: {
    practitioner: (state, mutation) => {state.practitioner = mutation;},
    patient: (state, mutation) => {state.patient = mutation;},
    sexe: (state, mutation) => {state.sexe = mutation;},
    token: (state, mutation) => {state.token = mutation;},
    idPatient: (state, mutation) => {state.idPatient = mutation;},
    parametres: (state, mutation) => {state.parametres = mutation;},
    amount: (state, mutation) => {state.amount = mutation;},
    displayPrice: (state, mutation) => {state.displayPrice = mutation;},
    picture_practitioner: (state, mutation) => {state.picture_practitioner = mutation;},
    visio: (state, mutation) => {state.visio = mutation;},
    visioActiveEvent: (state, mutation) => {state.visioActiveEvent = mutation;},
    maxPatient: (state, mutation) => {state.maxPatient = mutation;},
    black_listed: (state, mutation) => {state.black_listed = mutation;},
    payementType: (state, mutation) => {state.payementType = mutation;},
    motif: (state, mutation) => {state.motif = mutation;},
    confirmation: (state, mutation) => {state.confirmation = mutation;},
    idEvent: (state, mutation) => {state.idEvent = mutation;},
    slug: (state, mutation) => {state.slug = mutation;},
    profession: (state, mutation) => {state.profession = mutation;},
    address: (state, mutation) => {state.address = mutation;},
    dataEventType: (state, mutation) => {state.dataEventType = mutation;},
    dataEvent: (state, mutation) => {state.dataEvent = mutation;},
    event_types: (state, mutation) => {state.event_types = mutation;},
    text: (state, mutation) => {state.text = mutation;},
    client_secret: (state, mutation) => {state.client_secret = mutation;},
    charge_serialized: (state, mutation) => {state.charge_serialized = mutation;},
    filiation: (state, mutation) => {state.filiation = mutation;},    
    filiation_idPatient: (state, mutation) => {state.filiation_idPatient = mutation;},
    namePractitioner: (state, mutation) => {state.namePractitioner = mutation;},
    addressPractitioner: (state, mutation) => {state.addressPractitioner = mutation;},
    patientRegistration: (state, mutation) => {state.patientRegistration = mutation;},
    namePatient: (state, mutation) => {state.namePatient = mutation;},
    nameFiliation: (state, mutation) => {state.nameFiliation = mutation;},
    dateRDV: (state, mutation) => {state.dateRDV = mutation;},
    startdate: (state, mutation) => {state.startdate = mutation;},
    enddate: (state, mutation) => {state.enddate = mutation;},
    id_place: (state, mutation) => {state.id_place = mutation;},
    activeEvent: (state, mutation) => {state.activeEvent = mutation;},
    stripe: (state, mutation) => {state.stripe = mutation;},
    rights: (state, mutation) => {state.rights = mutation;},
    title_markup: (state, mutation) => {state.title_markup = mutation;},
  },
  actions: {},
  modules: {},
  plugins: [createPersistedState()]
});

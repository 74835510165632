import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/Login.vue"),
  },
  {
    path: "/:slug(.*)",
    props: true,
    name: "Praticien",
    component: () => import("../views/Practitioner.vue"),
  },
  {
    path: "/patient",
    name: "Patient",
    component: () => import("@/views/Patient.vue"),
  },
  {
    path: "/patient/change_password",
    name: "ChangePassword",
    component: () => import("@/components/patient/ChangePassword.vue"),
  },
  {
    path: "/patient/filiation",
    name: "Filiation",
    component: () => import("@/components/patient/Filiation.vue"),
  },
  {
    path: "/patient/waiting_list",
    name: "WaitingList",
    component: () => import("@/components/patient/WaitingList.vue"),
  },
  {
    path:"/reset_password",
    name: "ResetPassword",
    component: () => import("@/views/ResetPassword.vue"),
  },
  {
    path:"/reset/:slug/:idPatient/:token",
    props: true,
    name:"Reset",
    component: () => import("@/views/Reset.vue"),
  },
  {
    path:"/confirmation-rdv",
    name: "ConfirmRDV",
    component: () => import("@/views/BookingConfirmation.vue"),
  },
  {
    path: "/confirmation-success",
    name: "BookingSuccess",
    component: () => import("@/views/BookingSuccess.vue"),
  },
  {
    path: "/error",
    name: "Error",
    component: () => import("@/views/Error.vue"),
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
